import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';

import Hero from 'components/Hero';
import { resolveSlice } from 'utils/slice-resolver.js';
import useSiteMetadata from 'hooks/useSiteMetadata';

import css from './product_category.module.scss';

const DnaFoil = ({ data: { prismic }, location }) => {
  const { siteUrl } = useSiteMetadata();

  const content = prismic.allProduct_categorys.edges[0];
  if (!content) return null;

  const category = content.node;
  const image = category.hero_imageSharp;
  const slices = category.body || [];

  return (
    <>
      <Helmet>
        <title>{`SwissDeCode - ${category.category_name}`}</title>
        <meta description={RichText.asText(category.hero_description)} />
        <meta property="og:title" content={category.category_name} />
        <meta
          property="og:description"
          content={RichText.asText(category.hero_description)}
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:image" content={category.hero_image.url} />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        logo={category.hero_logo.url}
        logoClassName={css.heroLogo}
        subtitle={category.hero_subtitle}
        subtitleClassName={css.heroSubtitle}
        description={category.hero_description}
      />
      {slices.map(resolveSlice)}
    </>
  );
};

export default DnaFoil;

export const pageQuery = graphql`
  query CategoryBySlug($uid: String!) {
    prismic {
      allProduct_categorys(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            category_name
            hero_title
            hero_subtitle
            hero_logo
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_description
            _linkType
            body {
              ... on PRISMIC_Product_categoryBodyElements_section {
                type
                primary {
                  elements_title
                  elements_label
                }
                fields {
                  element_description
                  element_image
                  element_imageSharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  element_key_1
                  element_key_2
                  element_title
                }
              }
              ... on PRISMIC_Product_categoryBodyVideo_section {
                type
                primary {
                  video_description
                  video_label
                  video_link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  video_title
                }
              }
              ... on PRISMIC_Product_categoryBodyGrid_section {
                type
                label
                fields {
                  grid_card_column
                  grid_card_description
                  grid_card_subtitle
                  grid_card_title
                }
                primary {
                  grid_title
                  grid_label
                }
              }
              ... on PRISMIC_Product_categoryBodyChildren_section {
                type
                label
                fields {
                  child_description
                  child_image
                  child_link {
                    ... on PRISMIC_Product {
                      _meta {
                        uid
                        type
                      }
                      product_category {
                        ... on PRISMIC_Product_category {
                          hero_title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                  }
                  child_title
                }
                primary {
                  children_section_label
                  children_section_title
                }
              }
              ... on PRISMIC_Product_categoryBodyContact_section {
                type
                primary {
                  button_label
                  title
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;
